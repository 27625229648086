// types
import type { NavigationMenuItem } from '@revolutionprep/types'

// stores
import { useCourseMaterialsStore } from '@/store/course-materials'
import { useTrialStore } from '@/store/trial'

export function useMenuItems () {
  // stores
  const courseMaterialsStore = useCourseMaterialsStore()
  const { courseMaterials } = storeToRefs(courseMaterialsStore)

  const enrollmentStore = useEnrollmentStore()
  const { enrollments } = storeToRefs(enrollmentStore)

  const trialStore = useTrialStore()
  const { isActiveTrial } = storeToRefs(trialStore)

  // methods
  function getCourseMaterialsMenuItem () {
    if (!courseMaterials.value.length) {
      return
    }
    return {
      id: 'navItemCourseMaterials',
      title: 'Materials',
      icon: 'custom:ArchiveBox',
      to: '/materials',
      external: false
    }
  }

  function getSelfStudyMenuItem () {
    const selfStudyEnrollment = enrollments.value.find((enrollment) => {
      return enrollment.brand?.handle.includes('self-study')
    })

    if (!selfStudyEnrollment) {
      return
    }
    return {
      id: 'navItemSelfStudy',
      title: 'Self-Study',
      icon: 'custom:Book',
      to: '/self-study',
      external: false
    }
  }

  // computed
  const menuItems = computed(() => {
    return [
      {
        id: 'navItemActivityFeed',
        title: 'Activity Feed',
        icon: 'custom:Home',
        to: '/',
        external: false
      },
      {
        id: 'navItemMyCalendar',
        title: 'My Calendar',
        icon: 'custom:CalendarDays',
        to: '/my-calendar',
        external: false
      },
      {
        id: 'navItemScheduleSessions',
        title: 'Schedule Sessions',
        icon: 'custom:CalendarCheck',
        to: '/schedule-sessions',
        chipText: isActiveTrial.value ? 'TRIAL' : '',
        external: false
      },
      getCourseMaterialsMenuItem(),
      {
        id: 'navItemVideos',
        title: 'Videos',
        icon: 'custom:VideoPlay',
        to: '/videos',
        external: false
      },
      {
        id: 'navItemExams',
        title: 'My Exams',
        icon: 'custom:Paper',
        to: '/exams',
        external: false
      },
      getSelfStudyMenuItem()
    ].filter(menuItem => menuItem) as NavigationMenuItem[]
  })

  return {
    menuItems
  }
}
